import { ListCase, getNextPageParam, selectCases } from '@capturi/api-cases'
import request from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useInfiniteQuery } from '@tanstack/react-query'
import { FC, memo, useCallback } from 'react'
import { MdKeyboardArrowRight, MdLaunch } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import { MagicBox } from '@capturi/ui-components'
import { useTimeout } from 'react-use'
import { useCaseDetailsDrawer } from '../../components/CaseDetailsDrawer'

type DrawerOptions = {
  url: string
  getFilterRequestModel: () => Record<string, unknown>
  forceIsLoading?: boolean
}

export type CasesDrawerProps = DrawerOptions & Omit<DrawerProps, 'children'>

const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

export const CasesDrawer: FC<CasesDrawerProps> = ({
  url,
  getFilterRequestModel,
  isOpen,
  onClose,
}) => {
  const [isReady] = useTimeout(500)
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pl={3}
          pr={10}
        >
          <VStack alignItems="start" gap={0}>
            <Text fontSize="sm" fontFamily="body" fontWeight="normal">
              <Trans>Conversations</Trans>
            </Text>
          </VStack>
        </DrawerHeader>
        <DrawerBody overflow="hidden" p={0}>
          <DrawerBodyContent
            url={url}
            getFilterRequestModel={getFilterRequestModel}
            forceIsLoading={!isReady()}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const DrawerBodyContent: FC<Omit<DrawerOptions, 'title'>> = ({
  url,
  getFilterRequestModel,
  forceIsLoading = false,
}) => {
  const { getUserByUid } = useUsers()
  const openCaseDetailsDrawer = useCaseDetailsDrawer()

  const filter = getFilterRequestModel()

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['cases', url, filter],
      queryFn: async ({ pageParam }) =>
        await request.post(url, {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: 50,
                sortDirection: 1,
              },
        }),
      getNextPageParam,
      initialPageParam: null,
      select: selectCases,
    })

  const handleLoadMore = useCallback((): void => {
    fetchNextPage()
  }, [fetchNextPage])

  const handleOpenCaseDetailsDrawer = useCallback(
    (caseUid: string) => {
      openCaseDetailsDrawer({ caseUid })
    },
    [openCaseDetailsDrawer],
  )

  return (
    <Box h="full" overflowX="hidden">
      {isLoading || forceIsLoading ? (
        <VStack gap={4} mx={2} mt={2}>
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
          <Skeleton borderRadius="base" height="55px" w="100%" />
        </VStack>
      ) : (
        <VStack alignItems="stretch" gap={4} mt={2}>
          {(data?.pages ?? []).map((page) =>
            page.map((listItem) => (
              <CaseListItem
                key={listItem.uid}
                listItem={listItem}
                getUserByUid={getUserByUid}
                handleOpenCaseDetailsDrawer={handleOpenCaseDetailsDrawer}
              />
            )),
          )}
          <Flex my={4} justify="center" w="100%">
            <Button
              onClick={handleLoadMore}
              isDisabled={!hasNextPage || isFetchingNextPage}
              isLoading={isFetchingNextPage}
              borderRadius="md"
              size="sm"
            >
              {!hasNextPage ? (
                <Trans>No more conversations</Trans>
              ) : (
                <Trans>Load more</Trans>
              )}
            </Button>
          </Flex>
        </VStack>
      )}
    </Box>
  )
}

type CaseListItemProps = {
  listItem: ListCase
  getUserByUid: (uid?: string) => { name: string; email: string }
  handleOpenCaseDetailsDrawer: (uid: string) => void
}

const CaseListItem: FC<CaseListItemProps> = memo(
  ({
    listItem: { uid, created, users, subject, aiInsights },
    getUserByUid,
    handleOpenCaseDetailsDrawer,
  }) => {
    const user = getUserByUid(users.at(0))
    const { aiFeatures } = useFeatureFlags()
    return (
      <Card border={0} borderRadius="4px" shadow="md" overflow="hidden" mx={2}>
        <CardHeader
          bg="gray.200"
          cursor="pointer"
          px={4}
          py={2}
          onClick={() => {
            handleOpenCaseDetailsDrawer(uid)
          }}
          data-group
          _hover={{ bg: 'gray.300' }}
        >
          <VStack align="start" gap={0}>
            <HStack justifyContent="space-between" w="100%">
              <Text
                fontSize="md"
                fontWeight="medium"
                _groupHover={{ textDecoration: 'underline' }}
              >
                {subject}
              </Text>
              <Text
                _groupHover={{ visibility: 'hidden' }}
                fontSize="sm"
                textAlign="right"
                textColor="gray.600"
              >
                {i18n.date(new Date(created), dateFormat)}
              </Text>
              <IconButton
                aria-label={t`Go to conversation`}
                display="none"
                _groupHover={{ display: 'block' }}
                _hover={{ background: 'none' }}
                icon={<MdLaunch />}
                onClick={() => handleOpenCaseDetailsDrawer(uid)}
                size="2xs"
                textColor="gray.600"
                variant="ghost"
              />
            </HStack>
            <HStack
              justifyContent={aiFeatures ? 'space-between' : 'flex-end'}
              fontSize="xs"
              textColor="gray.600"
              w="100%"
            >
              {aiFeatures && (
                <HStack gap={0}>
                  {aiInsights?.keyTopic && <Text>{aiInsights?.keyTopic}</Text>}
                  {aiInsights?.keyTopic && aiInsights?.keySubTopic && (
                    <Icon as={MdKeyboardArrowRight} />
                  )}
                  {aiInsights?.keySubTopic && (
                    <Text>{aiInsights?.keySubTopic}</Text>
                  )}
                </HStack>
              )}
              <Text noOfLines={1}>
                {user.email.length > 0 ? user.email : user.name}
              </Text>
            </HStack>
          </VStack>
        </CardHeader>
        {aiFeatures &&
          aiInsights?.rootCause &&
          aiInsights.rootCause.length > 0 && (
            <MagicBox
              pl={4}
              pr={2}
              pt={2}
              pb={3}
              borderTopRightRadius="0px"
              borderTopLeftRadius="0px"
              borderBottomLeftRadius="4px"
              borderBottomRightRadius="4px"
            >
              <Text cursor="text" whiteSpace="pre-wrap">
                {aiInsights?.rootCause}
              </Text>
            </MagicBox>
          )}
      </Card>
    )
  },
)
